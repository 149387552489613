jQuery(document).ready(function ($) {
  function formatDate(selectedDate) {
    const date = new Date(selectedDate);
    const options = { weekday: "long", day: "numeric", month: "long" };
    return date.toLocaleDateString("nl-NL", options);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function updateDateText() {
    const selectedDate = $("#daySelector").val();
    const formattedDate = formatDate(selectedDate);
    const capitalizedDate = capitalizeFirstLetter(formattedDate);
    $("#selectedDay").text(selectedDate);

    $(".modal-summary__price").each(function () {
      const currentText = $(this).text();
      const updatedText = capitalizedDate;
      $(this).text(updatedText);
      // console.log("Text should be: ", updatedText);
    });
  }

  // Initial update on page load
  setTimeout(updateDateText, 50);

  // Update on change
  $("body").on("change", "#daySelector", function (e) {
    updateDateText();
  });

  // Update when the modal is shown
  $("body").on("shown.bs.modal", ".modal", function () {
    const productCat = $("#order-form").attr("product-cat");
    if (productCat === "Soep" || productCat === "Dessert" || productCat === "Combideal") {
      updateDateText();
      // console.log('Product cat is "Soep" or "Dessert"');
    } else {
      // console.log('Product cat is not "Soep" or "Dessert"');
    }
  });
});
