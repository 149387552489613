document.addEventListener('DOMContentLoaded', () => {
    const menus = document.querySelectorAll('.dbbk-menu');
    menus.forEach(menu => {
        const buttons = menu.querySelectorAll('.dbbk-menu__nav--inner__toggle:not(.toggle-disabled)');
        const selectedBg = menu.querySelector('.dbbk-menu__nav--inner .active') as HTMLElement;
        if(!buttons.length || !selectedBg) return;
        selectedBg.style.width = `${buttons[0].offsetWidth}px`;
        selectedBg.style.left = `${buttons[0].getBoundingClientRect().left - buttons[0].parentElement?.getBoundingClientRect().left}px`;
    
        buttons.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                if (selectedBg && button instanceof HTMLElement) {
                    selectedBg.style.width = `${button.offsetWidth}px`;
    
                    const buttonRect = button.getBoundingClientRect();
                    const parentRect = selectedBg.parentElement?.getBoundingClientRect();
    
                    if (parentRect) {
                        const leftPosition = buttonRect.left - parentRect.left;
                        selectedBg.style.left = `${leftPosition}px`;
                    }
    
                    buttons.forEach(btn => {
                        btn.classList.remove('active-filter');
                    });
    
                    button.classList.add('active-filter');
                }
            });
        });
    });
    
});