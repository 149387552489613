import Splide from '@splidejs/splide';

jQuery(document).ready(function ($) {
    if (!$('.dbbk-menu')) return;
    var $menus = $('.dbbk-menu');
    $menus.each(function (index, menu) {
        var $toggles = $(menu).find('.dbbk-menu__nav--inner__toggle:not(.toggle-disabled)');
        var $slider = $(menu).find('.splide-menu');
        get_menu_from_date($toggles.first().attr('data-date'), $slider, $toggles.first().attr('data-cat'));
        $toggles.on('click', function (e) {
            get_menu_from_date($(this).attr('data-date'), $slider, $(this).attr('data-cat'));
        });
    });


    function get_menu_from_date(date, $slider, cat) {
        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            data: {
                action: 'get_menu_items',
                date: date,
                cat: cat
            },
            beforeSend: function () {
                $slider.find('.splide__slide').fadeOut();
            },
            success: function (response) {
                response = JSON.parse(response);
                if (response.found || true) {
                    $slider.find('.splide__list').html(response.html);
                    var sliderElement = $slider[0];
                    var splideInstance = new Splide(sliderElement, {
                        type: 'slide',
                        perPage: 3,
                        perMove: 1,
                        pagination: false,
                        arrows: true,
                        breakpoints: {
                            768: {
                                perPage: 2,
                            },
                            576: {
                                perPage: 1,
                            },
                        },
                    }).mount();

                    splideInstance.refresh();

                    var slides = $slider.find('.splide__slide');
                    var perPage = splideInstance.options.perPage;
                    const arrowPrev = $slider.find('.splide__arrow--prev');
                    const arrowNext = $slider.find('.splide__arrow--next');
                    arrowPrev.addClass('nav-disabled');
                    if (slides.length <= perPage) {
                        arrowNext.addClass('nav-disabled');
                    }

                    splideInstance.on('active', function (e) {
                        if (e.index == 0) {
                            arrowPrev.addClass('nav-disabled');
                            arrowNext.removeClass('nav-disabled');

                        } else if (e.index == Math.ceil(slides.length / perPage)) {
                            arrowNext.addClass('nav-disabled');
                            arrowPrev.removeClass('nav-disabled');

                        } else {
                            arrowPrev.removeClass('nav-disabled');
                            arrowNext.removeClass('nav-disabled');
                        }
                    });

                    splideInstance.refresh();
                } else{
                    $slider.find('.splide__list').html(response.html);
                }



            }
        });
    }
});