//custom code
import "./custom/mobile-menu";
import "./custom/scroller";
import "./custom/torus-animations";
import "./custom/splide";
import "./custom/header-cycle";
import "./custom/product-filter";
import "./custom/checkbox";
import "./custom/woocommerce";
import "./custom/day-selector";
import "./custom/slider-nav";
import "./custom/like";
import "./custom/tab_title";
import "./custom/open-cat-button";
import "./custom/soep-dessert-day-selector";

//libraries
import "./lib/fslightbox";
