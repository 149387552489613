const { before } = require("@splidejs/splide/src/js/utils");

jQuery(document).ready(function ($) {
  $("body").on("change", "#attribute", function (e) {
    var selectedOptionId = $(this).children("option:selected").attr("id");
    $("body #order-form").attr("data-product", selectedOptionId);
  });

  $("body").on("change", "#order-form input, #order-form select", function (e) {
    updatePrice();
  });

  $("body").on("click", "#pdp-atc", function (e) {
    e.preventDefault();
    var productId = $("body #order-form").attr("data-product");
    var quantity = $("body #aantal-porties").val();
    addToCart(productId, quantity);
  });
  if (
    document.querySelector("#delivery-zip") &&
    window.localStorage.getItem("zip")
  ) {
    $("#delivery-zip").val(window.localStorage.getItem("zip"));
    var selectedDay = $("body #selectedDay").text();

    getDeliveryTimes(window.localStorage.getItem("zip"), selectedDay, "auto");
  }
  $("body").on("input", "#delivery-zip", function (e) {
    var selectedDay = $("body #selectedDay").text();
    getDeliveryTimes($(this).val(), selectedDay);
  });

  if (document.querySelector("#billing_postcode")) {
    document.querySelector("#billing_postcode").value =
      window.localStorage.getItem("zip");
  }

  updatePrice();

  function updatePrice() {
    let total = 0;

    $("body .order-form-row").each(function (index, row) {
      let price = 0; // Default price to 0

      // Check if the .price element has the ID "attribute"
      if ($(row).find(".price").attr("id") === "attribute") {
        if ($(row).find(".price").is('input[type="checkbox"]')) {
          if ($(row).find(".price").prop("checked")) {
            price = parseFloat($(row).find(".price").val());
          }
        } else {
          price = parseFloat($(row).find(".price").val());
        }
      }

      if ($(row).find(".price").attr("id").includes("upsell")) {
        if ($(row).find(".price").is('input[type="checkbox"]')) {
          if ($(row).find(".price").prop("checked")) {
            price = parseFloat($(row).find(".price").val());
          }
        } else {
          price = parseFloat($(row).find(".price").val());
        }
      }

      if (isNaN(price)) {
        price = 0;
      }

      let quantity = parseFloat($(row).find(".quantity").val());
      if (isNaN(quantity)) {
        quantity = 1;
      }

      total += price * quantity;
    });

    $("body #order-price").text("€ " + total.toFixed(2).replace(".", ","));
  }

  function getDeliveryTimes(zip, day, type = "manual") {
    if (zip.length != 4) {
      $("body #notice").fadeOut();
    }
    $.ajax({
      type: "POST",
      url: "/wp-admin/admin-ajax.php",
      data: {
        action: "get_delivery_times",
        zip: zip,
        storedZip: zip,
        day: day,
        type: type,
      },
      success: function (response) {
        response = JSON.parse(response);
        if (response.found) {
          $("body #delivery-zip").css("box-shadow", "none");
          $("body #delivery-time").css("box-shadow", "none");
          var minimum = response.minimum;
          var noFee = response.nofee;
          var html = response.html;
          $("body #delivery-time").html(html);
          $("body #orderMin").text(minimum);
          $("body #freeFrom").text(noFee);
          $("body #notice").fadeIn();

          if (response.autofill) {
            $("body #delivery-zip").val(response.zip);
            $("body #delivery-time option").each(function () {
              if ($(this).val() == response.day.time) {
                $(this).prop("selected", true);
              }
            });
          }
          $("body .order-form-delivery__zip img").fadeIn();
        } else {
          $("body #delivery-time").html(response.selector);
          $("body .order-form-delivery__zip img").fadeOut();
          $("body #notice").fadeOut();

          if (response.modal == true) {
            $("body").append(response.html);
            var modalElement = document.getElementById("noService");
            var modal = new bootstrap.Modal(modalElement);
            modal.show();
            modalElement.addEventListener("hidden.bs.modal", function (e) {
              modal.dispose();
              modalElement.remove();
            });
          }
        }
      },
    });
  }

  function addToCart(productId, quantity = 1) {
    var upsells = [];
    $("body .order-form-row").each(function (index, row) {
      if ($(row).hasClass("upsell")) {
        if ($(row).find(".price").prop("checked")) {
          var upsell = {
            product_id: $(row).find(".price").attr("data-upsell"),
            quantity: $(row).find(".quantity").val(),
          };
          upsells.push(upsell);
        }
      }
    });
    var zip = $("body #delivery-zip").val() || null;
    var time = $("body #delivery-time").val() || null;
    var deliveryFee =
      $("body #delivery-time").find("option:selected").attr("data-fee") || null;
    var selectedDay = $("body #selectedDay").text() || null;
    var message = $("body #message").val() || null;

    if (!window.localStorage.getItem("zip")) {
      window.localStorage.setItem("zip", zip);
    } else if (window.localStorage.getItem("zip") != zip) {
      window.localStorage.setItem("zip", zip);
    }

    if (time != -1) {
      $("body #delivery-zip").css("box-shadow", "none");
      $("body #delivery-time").css("box-shadow", "none");
      $.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php",
        data: {
          action: "add_to_cart",
          product_id: productId,
          quantity: quantity,
          upsells: upsells,
          zip: zip,
          time: time,
          day: selectedDay,
          delivery_fee: deliveryFee,
          message: message,
          // product_cat =
        },
        beforeSend: function () {
          // console.log("deliveryFee", deliveryFee);
        },
        success: function (response) {
          console.log(JSON.parse(response));
          $("body .woo-modal__wrap").html(JSON.parse(response).modal_html);
          $("body .woo-modal").addClass("woo-modal__active");
          $("body .woo-modal .continue-shop").on("click", function (e) {
            e.preventDefault();
            $(".woo-modal").removeClass("woo-modal__active");
          });
          $(document.body).trigger("wc_fragment_refresh");

          if ($("body #orderModal")) {
            var modalElement = document.getElementById("orderModal");
            var modal = bootstrap.Modal.getInstance(modalElement);
            modalElement.remove();
            modal.dispose();
          }
        },
      });
    } else {
      if ($("body #delivery-time").val() == -1) {
        $("body #delivery-time").css("box-shadow", "inset 0 0 0 1px red");
      }
      if ($("body .order-form-delivery__zip img").css("display") == "none") {
        $("body #delivery-zip").css("box-shadow", "inset 0 0 0 1px red");
      }
    }
  }
  $("body").on(
    "click",
    ".dbbk-menu .content-product .btn, .dbbk-weekmenu .content-product .btn",
    function (e) {
      e.preventDefault();
      $.ajax({
        url: "/wp-admin/admin-ajax.php",
        type: "POST",
        data: {
          action: "open_order_modal",
          product_id: $(this).attr("data-product"),
        },
        success: function (response) {
          $("body").append(response);
          var modalElement = document.getElementById("orderModal");
          var modal = new bootstrap.Modal(modalElement);
          modal.show();
          modalElement.addEventListener("hidden.bs.modal", function (e) {
            modal.dispose();
            modalElement.remove();
          });
          $('#order-form input[type="checkbox"]').each(function (index, el) {
            $pairedSelect = $(this)
              .closest(".order-form-row")
              .find(".select-wrap");
            $pairedSelect.addClass("disabled");
          });
          updatePrice();
          if (
            document.querySelector("#delivery-zip") &&
            window.localStorage.getItem("zip")
          ) {
            $("#delivery-zip").val(window.localStorage.getItem("zip"));
            var selectedDay = $("body #selectedDay").text();

            getDeliveryTimes(
              window.localStorage.getItem("zip"),
              selectedDay,
              "auto"
            );
          }
        },
      });
    }
  );

  document.addEventListener("click", function (event) {
    // Check if the clicked element is a button with the attribute 'data-product'
    if (event.target.hasAttribute("data-product")) {
      // console.log("Button clicked");

      setTimeout(function () {
        var daySelector = document.getElementById("daySelector");

        if (daySelector) {
          // console.log("Element exists");
          var options = daySelector.getElementsByTagName("option");
          if (options.length === 1) {
            var singleOptionValue = options[0].getAttribute("value");
            document.getElementById("selectedDay").innerText =
              singleOptionValue;
          }
        } else {
          // console.log("Element does not exist");
        }
      }, 1000);
    }
  });
});

/* -------------------------------------------------------------------------- */
/*        Sets fee to 0 on the day that is higher than the no-fee value       */
/* -------------------------------------------------------------------------- */

jQuery(document).ready(function ($) {
  function sumProductSubtotals() {
    $(".woocommerce-cart-form__contents").each(function (index) {
      var total = 0;
      $(this)
        .find("td.product-subtotal")
        .each(function () {
          var priceText = $(this)
            .text()
            .replace(/[^0-9,]+/g, "")
            .replace(",", ".");
          var price = parseFloat(priceText);
          if (!isNaN(price)) {
            total += price;
          }
        });

      $(this).find(".total-price").remove();
      var formattedTotal = wc_price(total);
      $(this).append(
        '<div style="display:none;" class="total-price">Total: ' +
          formattedTotal +
          "</div>"
      );

      var orderDayId = `#order-day-${index + 1}`;
      var orderDayElement = $(orderDayId);
      if (orderDayElement.length) {
        var noFeeText = orderDayElement.attr("no-fee").replace(",", "."); // Replace comma with dot for parseFloat
        var noFee = parseFloat(noFeeText);
        if (!isNaN(noFee)) {
          if (total > noFee) {
            orderDayElement
              .find(".woocommerce-Price-amount bdi")
              .each(function () {
                $(this).html(
                  $(this)
                    .html()
                    .replace(/\d+([,.]\d+)?/g, "0,00")
                );
              });
          }
        }
      }
    });
  }

  function wc_price(amount) {
    return new Intl.NumberFormat("nl-NL", {
      style: "currency",
      currency: "EUR",
    })
      .format(amount)
      .replace(".", ",");
  }

  // Call the function on page load
  sumProductSubtotals();

  // Optionally, call the function when the cart is updated
  $(document.body).on("updated_cart_totals", sumProductSubtotals);
});
