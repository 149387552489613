document.addEventListener('DOMContentLoaded', () => {
    if (!document.getElementById('catToggle')) return;

    const catToggle = document.getElementById('catToggle');
    const categories = document.querySelector('.archive-categories');
    console.log(categories);

    catToggle.addEventListener('click', () => {
        categories.classList.toggle('active');
    });

});