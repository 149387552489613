jQuery(document).ready(function ($) {
    $('body #order-form input[type="checkbox"]').each(function (index, checkbox) {
        $parentRow = $(this).closest('.order-form-row');
        $parentRow.find('.select-wrap').addClass('disabled');

    }
    );
    $('body').on('change', '#order-form input', function (e) {
        $parentRow = $(this).closest('.order-form-row');
        if ($(e.target).hasClass('checked')) {
            $(e.target).removeClass('checked');
        } else {
            $(e.target).addClass('checked');

        }
        if (!e.target.checked) {
            $parentRow.find('.select-wrap').addClass('disabled');
        } else {
            $parentRow.find('.select-wrap').removeClass('disabled');
        }
    });
});