jQuery(document).ready(function ($) {
    if (!$('.content-product__content--likes')) return;

    $('.content-product__content--likes').on('click', function (e) {
        e.preventDefault();
        var $this = $(this);
        var product_id = $this.closest('.content-product').data('product-id');

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'POST',
            data: {
                action: 'like_product',
                product_id: product_id
            },
            success: function (response) {
                response = JSON.parse(response);

                console.log(response);
                if (response.liked == true) {
                    alert('You have already liked this product.');
                } else if(response.liked == false) {
                    if(response == 1){
                        $($this).find('p').html('<span class="counter">' + response + '</span> stem');
                    } else if(response > 1){
                        $($this).find('.counter').text(response);

                    }
                    $this.addClass('liked');
                } else if(response.html){
                    $('body').append(response.html);
                    var modalElement = document.getElementById('not-liked');
                    var modal = new bootstrap.Modal(modalElement);
                    modal.show();
                    modalElement.addEventListener('hidden.bs.modal', function (e) {
                        modal.dispose();
                        modalElement.remove();
                    });

                }
            }
        });
    });
});