    (function () {
        var originalTitle = document.title;
        var temporaryTitle = "Psst! Kom terug...";
        var isVisible = true;

        function changeTitle() {
            if (!isVisible) {
                document.title = temporaryTitle;
                setTimeout(function () {
                    document.title = originalTitle;
                }, 3000);
            }
        }

        // Check the tab visibility when the visibility changes
        document.addEventListener('visibilitychange', function () {
            isVisible = !document.hidden;
            if (!isVisible) {
                changeTitle();
            }
        });

        // Set an interval to check the tab visibility periodically
        setInterval(changeTitle, 8000); // Adjust the interval as needed
    })();