import Splide from "@splidejs/splide";

document.addEventListener("DOMContentLoaded", () => {
  if (document.getElementsByClassName("splide-menu").length >= 1) {
    var sliders = document.querySelectorAll(".splide-menu");
    sliders.forEach(function (slider) {
      var slide = new Splide(slider as HTMLElement, {
        type: "slide",
        perPage: 3,
        perMove: 1,
        pagination: false,
        arrows: true,
        drag: false,
        breakpoints: {
          768: {
            perPage: 2,
          },
          576: {
            perPage: 1,
          },
        },
      }).mount();
      slide.refresh();

      setTimeout(function () {
        var slides = slider.querySelectorAll(".splide__slide");

        var perPage = slide.options.perPage;
        const arrowPrev = slider.querySelector(".splide__arrow--prev");
        const arrowNext = slider.querySelector(".splide__arrow--next");

        arrowPrev?.classList.add("nav-disabled");
        if (slides.length <= perPage) {
          arrowNext?.classList.add("nav-disabled");
        }
        slide.on("active", function (e) {
          if (e.index == 0) {
            arrowPrev?.classList.add("nav-disabled");
            arrowNext?.classList.remove("nav-disabled");
          } else if (e.index == Math.ceil(slides.length / perPage)) {
            arrowNext?.classList.add("nav-disabled");
            arrowPrev?.classList.remove("nav-disabled");
          } else {
            arrowPrev?.classList.remove("nav-disabled");
            arrowNext?.classList.remove("nav-disabled");
          }
        });
      }, 300);
    });
  }

  if (document.getElementsByClassName("splide-reviews")) {
    var sliders = document.querySelectorAll(".splide-reviews");
    sliders.forEach(function (slider) {
      new Splide(slider as HTMLElement, {
        type: "slide",
        perPage: 3,
        perMove: 1,
        pagination: false,
        arrows: false,
        breakpoints: {
          992: {
            perPage: 2,
          },
          768: {
            perPage: 1,
          },
          576: {
            perPage: 1,
          },
        },
      }).mount();
    });
  }
});
