document.addEventListener("DOMContentLoaded", function () {
    if (true || window.matchMedia("(max-width: 991px)").matches) {
        var count = true;
        var topItem: HTMLCollection = document.getElementsByClassName(
            "top-menu-usp__item"
        )!;
        var i: number = 0;

        var topBarItemCounter = function () {

            if (i == topItem.length) {
                clearInterval(this);
                i = 0;
            } else {
                var increment = i++;
                for (var item = 0; item < topItem.length; item++) {
                    if (
                        topItem[item].classList.contains(
                            "item-active"
                        )
                    ) {
                        topItem[item].classList.remove(
                            "item-active"
                        );
                    }
                }
                topItem[increment].classList.add(
                    "item-active"
                );
            }
        };
        setInterval(topBarItemCounter, 7000);
        topBarItemCounter();
    }
});